<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          我的信息
        </h5>
      </b-card-header>
      <b-card-body>
        <validation-observer ref="simpleRules">
          <b-form
            class="p-2"
            submit="submit"
            enctype="multipart/form-data"
          >

            <b-form-group
              label="我的姓名"
              label-for="input-default"
            >
              <b-form-input
                id="input-default"
                v-model="form.name"

                readonly
              />

            </b-form-group>

            <b-form-group
              label="我的号码"
              label-for="input-default"
            >
              <b-form-input
                id="input-default"
                v-model="form.phone"

                readonly
              />

            </b-form-group>






            <b-form-group
              label="密码"
              label-for="input-default"
            >

              <b-form-input
                id="input-default"
                v-model="form.password"
                placeholder="密码不改请留空"
              />

            </b-form-group>

            <b-form-group>
              <div class="d-flex mt-5">
                <b-button
                  variant="primary"
                  class="mr-2"
                  type="button"
                  @click="updateStudent"
                >
                  确定更新
                </b-button>

              </div>

            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
  BFormGroup, BFormInput, BForm, BButton, BCardHeader, BCardBody, BCard,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCard,
    ValidationProvider,
    ValidationObserver,

  },
  props: {
    aid: integer,
  },
  data() {
    return {
      required,
      integer,
      form: {
        id: 0,
        name: '',
        password: '',
        phone: '',
      },
      preview: false,
      previewImg: '',
      file: null,
      sourceType: this.$variable.source,
      sourceSelected: 2,
    }
  },
  created() {
    this.getStudent()
  },
  methods: {

    getStudent() {

      this.UserData().then(res => {
        this.form = res.data.data
        this.form.password = ''
      })
    },
    updateStudent() {
      this.UserUpdate(this.form).then(res => {
        this.showToast(res.data.code, res.data.msg)
      })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.ql-container {
  height: 500px;
}

</style>
